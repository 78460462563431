import React from 'react'
import './PortfolioBanner.scss'
function PortfolioBanner() {
    return (
        <React.Fragment>
                <div className="portfolioBanner">
                    <img src="/images/portfolio/portfolio.svg" alt="portfolio" />
                </div>
        </React.Fragment>
    )
}

export default PortfolioBanner
